.categoryStyle{
    font-size: 20px;
}

.menuStyle{
    font-size: 28px;
    color: #E22E2E;
    text-decoration: underline;
}

.categoryItemStyle{
    font-size: 16px;
    color: black;
    font-weight: bolder;
}

.maninPageCategoryName{
    font-size: 18px;
    font-weight: bolder;
    color: #E22E2E;
}

.mainPageProductName{
    font-size: 14px;
    font-weight: bolder;
}


@media (max-width: 1025px) { 
    .scrollingWrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    
    .cartItem {
        display: inline-block;
        padding-left: 10px;
    }
}
  


@media (max-width: 575px) { 
}