
.heigthCover{
    min-height: 400px !important;
}

.youAre{
    font-size: 28px;
    color:white;
    text-transform: uppercase;
}


@media (max-width: 1025px) { 
    .heigthCover{
        min-height: 320px !important;
    }
}

@media (max-width: 575px) { 
    .displayNoMobile{
        display: none;
    }
}