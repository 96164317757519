.addressBarDivStyle{
    display: flex;
    justify-content: flex-end;
    align-items: 'center';
}

.scuterStyle{
    width: 25%;
}

.heigthCover{
    min-height: 400px !important;
}

.featureImageStyle{
    width: 60%;
}

.categoryTitle{
    font-size: 24px;
    color: white;
    font-family: 'Courgette';
}

.categoryName{
    color: #484848;
    font-size: 12px;
    text-transform: uppercase;
}

.pictureCategoryHeight{
    height: 150px
}

.featureName{
    color: #484848;
    font-size: 12px;
}

.featureNumber{
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.cardStyle{
    border-radius: 15px !important;
}

.downloadTitle{
    font-size: 40px;
    color: black;
    text-transform: uppercase;
}

.smallTextStyle{
    color: black;
    font-weight: bolder;
    font-family: 'Courgette';
    font-size: 18px;
}

.downloadDescription{
    font-size: 20px;
    color: white;
}

.coverTitle{
    font-size: 60px;
    color: white;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: bolder;
}

.workWithUsName{
    color: black;
}

.workWithUsTitle{
    font-size: 40px;
    color: black;
    text-transform: uppercase;
    line-height: 50px;
}


.workWithUsDescription{
    text-transform: uppercase;
    font-weight: bolder;
    color: black;
}

.noShowDesktop{
    display: none;
}

.noShowMobile{
}

.scooterAlign{
    margin-top: -10rem;
}




@media (max-width: 1025px) { 
    .featureImageStyle{
    
    }

        
    .scuterStyle{
        width: 35%;
    }

    .displayRedLine{
        display: none;
    }

    .noShowMobile{
        display: none !important;
    }

    .noShowDesktop{
        display: block ;
    }

    .featureName{
        color: #484848;
        font-size: 14px;
    }

    .scooterAlign{
        margin-top: -10rem;
    }
    
}


@media (max-width: 575px) { 
    .featureImageStyle{
    
    }

    .addressBarDivStyle{
        display: inline;
        justify-content: center;
        align-items: 'center';
    }

    .taportyMarginTop{
        margin-top: 20px;
    }

    .coverTitle{
        color: white;
        font-size: 30px;
    }
    
    .scuterStyle{
        display: none;
    }

    .heigthCover{
        min-height: 320px !important;
    }

    .pictureCategoryHeight{
        height: 80px
    }

    .marginDownload{
        margin-top: 10px;
    }

    .workWithUsTitle{
        font-size: 26px;
    }

    .featureName{
        color: #484848;
        font-size: 12px;
    }

    .scooterAlign{
        margin-top: -10rem;
    }

    .scooterAlign{
        display: none;
    }

}