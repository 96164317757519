.styleMenu{
    border-right: 1px solid black;
}

.stickyContainer{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    z-index: 99;
}

@media (max-width: 1025px) { 
    .styleMenu{
        border-right: none;
    }    
}
  


@media (max-width: 575px) { 

    .heightCard{
        height: 100px;
    }

}