.borderStyle{
    height: 5px;
    background: linear-gradient(to right, #9a76ff,#79cbff);
}

.cardStyle{
    padding: 20px;
    border-radius: 0px !important;
    border-color: white !important;
}

.cardMiddleStyle{
    background-color: #79cbff !important;
    padding: 20px;
    border-radius: 0px !important;
    border-color: white !important;
}

.titleStyle{
    color: #79cbff;
    text-transform: uppercase;
    font-weight: bold;
}

.titleStyleMiddle{
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.priceStyle{
    font-size: 34px;
    color: black;
    font-weight: bolder;
}

.priceStyleMiddle{
    font-size: 34px;
    color: white;
    font-weight: bolder;
}

.priceSmallStyle{
    font-size: 16px;
    color: black;
    font-weight: bolder;
    vertical-align: 70%;
}

.priceSmallStyleMiddle{
    font-size: 16px;
    color: white;
    font-weight: bolder;
    vertical-align: 70%;
}

.descriptionStyle{
    color:black;
    font-weight: bold;
}

.descriptionStyleMiddle{
    color:white;
    font-weight: bold;
}

.buttonMiddleStyle{
    background-color: #e93d8f !important;
    border-color: #edf7ff  !important;
    color: white;
    font-size: 20px !important;
    width: 220px;
    font-family: 'Comfortaa' !important;
    font-weight: bold !important;
  }

.buttonSideStyle{
    background-color: #79cbff !important;
    border-color: #edf7ff  !important;
    color: white;
    font-size: 20px !important;
    width: 220px;
    font-family: 'Comfortaa' !important;
    font-weight: bold !important;
}
  
.divStyle{
    display: flex;
}

.cardImageStyle{
    width: 30%;
}

@media (max-width: 720px) { 
    .divStyle{
        flex-wrap: wrap
    }

    .cardImageStyle{
        width: 50%;
    }
    
}