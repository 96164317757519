.imageStyle{
    width: 30%;
}

.rowBackgroundColor{
    background-color: #484848;
    margin-top: 2rem;
}

.footerTextHeader{
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.footerTextItem{
    color: #E22E2E;
    margin: 0px;
    font-weight: bold;
    font-size: 12px;
}

.contactTextElement{
    margin: 0px;
    color: white;
    font-size: 12px;
}

.storeLogo{
    width: 50%;
}

.separatorDiv{
    width: 80%;
    height: 1px;
    background-color: grey;
    margin-left: 10%;
    margin-top: 20px;
}

@media (max-width: 720px) { 
    .imageStyle{
        width: 30%;
    }

    .storeLogo{
        width: 100%;
    }
    
}