.ModalTitle {
    font-family: 'Fondamento'; 
    line-height: 40px; 
    color: white; 
    font-style: normal; 
    font-weight: 400;
    color: #88C000; 
}

.ModalDescription {
    font-family: 'lato';
    font-size: 1.3vw;
}

@media (max-width: 780px) {
    .ModalTitle {
        font-size: 7vw; 
    }

    .ModalDescription{
        font-size: 3vw;
    }
}

@media (max-width: 480px) {
    .ModalTitle {
        font-size: 8vw; 
    }

    
    .ModalDescription{
        font-size: 5vw;
    }
}