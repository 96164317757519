.titleFeature{
    font-family: 'Courgette';
    color: #E22E2E;
    font-size: 22px;
}

.titleDownload{
    color: #E22E2E;
    font-size: 22px;
}

.descriptionDownload{
    color: black;
}

.image1MarginTop{
    margin-top: -15rem;
}

.image2MarginTop{
    margin-top: -15rem;
}

@media (max-width: 1025px) { 
    .image1NoDisplay{
        display: none;
    }
}
  
  
  
@media (max-width: 575px) { 
    .image2MarginTop{
        margin-top: -10rem;
    }

    .image1MarginTop{
        margin-top: 0rem;
    }
}