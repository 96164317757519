/* Comfortaa,Quicksand
ACCENT_RED = #EB4D4D;
RED = #E22E2E;
PINK = #E19991;
BLUE = #1565C0;
LIGHT_BLUE = #7FAFE1;
LIGHT_GREY = #E3E3E3;
DARK_GREY = #484848;
BEIGH = #F3EED9;
*/
.App {
  text-align: center;
}

h1 {
  font-family: 'Quicksand';
  color: 'black';
}

h2 {
  font-family: 'Quicksand';
  color: 'black';
}

h3 {
  font-family: 'Quicksand';
  color: 'black';
}

p {
  font-family: 'Comfortaa';
  color: #484848;
  font-size: 14px;
}

span {
  font-family: 'Comfortaa';
  color: #484848;
  font-size: 14px;
}

.errorStyle{
  color:red;
}

.formLabelStyle{
  font-family: 'Comfortaa';
  color: #484848;
}

.textStyle{
  font-family: 'Comfortaa' !important;
  color: #484848 !important;
}

.colorRed {
  color: #E22E2E;
}

.colorAccentRed{
  color: #EB4D4D;
}

.containerStyle{
  min-height: 30vh;
}

.buttonGeneralStyle{
  background-color: #E22E2E !important;
  border-radius: 1px !important;
  border-color: #E22E2E !important;
  color: white !important;
  font-size: 14px !important;
  width: 220px;
  font-family: 'Comfortaa' !important;
}

.linkButton {
  cursor: pointer;
  color: #1565C0;
}

.linkButton:hover {
  text-decoration: underline;
}

.rowMarginForm{
  margin-top: 1rem;
}

.colMarginForm{
  margin-top: 0rem;
}

.dateTimeStyle{
  font-size: 16px !important;
  font-family: 'PT Sans';
  border: 1px solid #ced4da !important;
  border-radius: 3px !important;
  padding: 17px !important;
  width: 100%;
  color: grey;
  height: 30px;
}

.dropDown>button{
  background-color: #E22E2E;
  border-color: white  !important;
  color: white;
  width: 220px;
  font-size: 16px !important;
  font-family: 'PT Sans' !important;
}

@media (max-width: 1025px) { 
  .colMarginForm{
    margin-top: 1rem;
  }

  .rowMarginForm{
    margin-top: 0rem;
  }
}



@media (max-width: 575px) { 
  .rowMarginForm{
    margin-top: 0rem;
  }

  .colMarginForm{
    margin-top: 1rem;
  }
}