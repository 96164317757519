.cardStyle{
    border-radius: 15px !important;
}

.textName{
    text-transform: uppercase;
    color: #484848;
    font-weight: bolder;
}

.heightCard{
    height: 150px;
}

@media (max-width: 1025px) { 

}

@media (max-width: 575px) { 
    .heightCard{
        height: 80px;
    }
    
}
