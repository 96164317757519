.navStyle{
    border-bottom: 1px #E3E3E3 solid;
    box-shadow: 1px 2px #E3E3E3;
}

.imageNavStyle{
    width: 150px;
}

.imageStyle{
    width: 50%;
}

.menuStyle{

}

.nameStyleNotSelected{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}

.nameStyleSelected{
    text-transform: uppercase;
    background-color: #E22E2E;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 12px;
}


@media (max-width: 720px) { 
    .imageNavStyle{
        width: 50px;
    }

    .imageStyle{
        width: 100%;
    }
    
}