.menuBorder{
    border-right: 1px solid black ;
    margin-top: 2rem;
}


@media (max-width: 1025px) { 
    .menuBorder{
        border-right: none ;
    }
}