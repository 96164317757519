.titleStyle{
    color: #E22E2E;
    font-size: 18px;
    font-family: 'Courgette';
}

.titleSecondSection{
    color: white;
    font-size: 18px;
}

.descriptionSecondSection{
    color: white;
    font-size: 12px;
}



@media (max-width: 1025px) { 

}
   
@media (max-width: 575px) { 

}