.alertStyle{
    z-index: 99 !important; 
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: '50%' !important; 
    margin-top: 10px !important
}

.description{
    font-size: 12px;
}

@media (max-width: 1024px) { 
    .description{
        font-size: 30px;
    }

    .alertStyle{
        left: 20% !important;
        transform: translateX(-10%) !important;
        width: '90%' !important; 
    }
}

@media (max-width: 575px) { 
    .alertStyle{
        left: 20% !important;
        transform: translateX(-10%) !important;
        width: '80%' !important; 
        margin-top: 2px !important
    }

    .description{
        font-size: 10px;
    }
}