.choosenText{
    color: #E22E2E;
    font-size: 16px;
    text-transform: uppercase;
}

.notChoosenText{
    font-size: 16px;
    text-transform: uppercase;
}

.sectionTitle{
    font-size: 24px;
    text-transform: uppercase;
    color: #E22E2E;
}

.fontTableHeader{
    color: white;
    font-weight: bolder;
}

.textType{
    color: black;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 11px;
}

.textSection{
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
}

@media (max-width: 1025px) { 
    .hideMobile{
        display: none;
    }
}
  
  
  
@media (max-width: 575px) { 
    .hideMobile{
        display: none;
    }
}