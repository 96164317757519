.borderStyle{
   border-right: 1px solid grey
}

@media (max-width: 1025px) { 

}

@media (max-width: 575px) { 
    .borderStyle{
        border-right: none;
     }
    
}
