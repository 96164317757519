.cardStyle{
    border-radius: 15px !important;
}

.textName{
    text-transform: uppercase;
    color: #E22E2E;
    font-weight: bolder;
    margin-bottom: 5px;
}

.categoryStyle{
    font-weight: bolder;
    font-family: 16px;
}

.infoHeight{
    height: 120px;
    padding: 10px;
    color: black;
}

.cardImageHeight{
    height: 150px;
}


@media (max-width: 1025px) { 
    .scrollingWrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    
    .cartItem {
        display: inline-block;
        padding-left: 10px;
    }
}
  


@media (max-width: 575px) { 

    .cardImageHeight{
        height: 100px;
    }
    
    .infoHeight{
        height: 80px;
    }


}