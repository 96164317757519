.card {
    padding: 30px
}

.titleStyle{
    font-size: 30px;
    color: #E22E2E;
    font-family: 'Comfortaa';
    font-weight: bolder;
}


.linkStyle{
    color:#E22E2E;
    font-weight: bold;
}

@media (max-width: 1025px) { 
    .titleStyle{
    }
}

@media (max-width: 575px) { 
    .titleStyle{
    }
}